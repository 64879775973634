<script>
import Layout from "../../../../../layouts/main";
import { FormWizard, TabContent } from "vue-form-wizard";

import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import {
  required,
  decimal,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouveau contrat",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormWizard, TabContent },
  data() {
    return {
      title: "Nouveau contrat",
      suppliers: [],
      vrules: {
        contractRef: {
          maxLength: 64,
        },
      },
      contractForm: {
        contractRef: "",
        contractSupplier: "",
        contractType: "",
        contractPeriodFrom: "",
        contractPeriodTo: "",
        contractAmnt: "",
        contractComments: "",
        // catering contract
        contractAllocatedHostingAmmount: "",
        contractAllocatedCateringAmmount: "",
        contractAllocated3dAmmount: "",
        // gardenig contract
        contractPesticidesAmountAllocated: "",
        contractConstructionsAmountAllocated: "",
        contractRollingEquipmentsAmountAllocated: "",
        contractMaintenanceAmountAllocated: "",
        // other contract
        contractSignatureDate: "",
        contractEffectiveDate: "",
      },
      types: [
        {
          id: "catering",
          name: "Full Catering",
        },

        {
          id: "Maintenance",
          name: "Maintenance",
        },

        {
          id: "gardening",
          name: "Espace Vert",
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Contrats",
          href: "/",
        },
        {
          text: "Nouveau contrat",
          active: true,
        },
      ],
    };
  },

  validations: {
    contractForm: {
      contractRef: {
        required,
        maxLength: maxLength(64),
      },
      contractSupplier: { required },
      contractType: { required },
      contractPeriodFrom: { required },
      contractPeriodTo: { required },
      contractAmnt: { required, decimal },
      contractAllocatedHostingAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering";
        }),
      },
      contractAllocatedCateringAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering";
        }),
      },
      contractAllocated3dAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering";
        }),
      },
    },
  },

  created() {
    this.fetchSuppliersList();
  },

  methods: {
    fetchSuppliersList() {
      this.$http
        .post("/contracts/suppliers/list")
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    _validateContractAmnts() {
      var validated = true;
      // var contractType = this.contractForm.contractType;

      return validated;
    },
    formSubmit() {
      this.submitted = true;
      var valid = true;
      // var validFormData = this._validateContractAmnts();
      var router = this.$router;
      if (valid) {
        this.$http
          .post("/contracts/contracts/store", this.contractForm)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                Swal.fire("Félicitations!", res.data.original.msg, "success");
                setTimeout(function() {
                  router.push({ name: "base.contracts.contracts.index" });
                }, 3000);
                break;

              case 500:
                Swal.fire("Avertissement!", res.data.original.msg, "warning");
                break;
            }
          })
          .catch((error) => {
            Swal.fire("Erreur!", error.message, "error");
          })
          .finally(() => {});
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <form-wizard color="#556ee6">
            <!-- Global contract infos -->
              <tab-content icon="mdi mdi-account-circle" title="1- Infos Générales">
                <div class="row">
                  <div class="card-body">
                    <b-alert show variant="info">
                      <i class="mdi mdi-alert-circle-outline mr-2"></i>Les
                      champs obligatoires sont marqués avec (*)
                    </b-alert>
                    <div class="row">
                      <div class="col-md-4">
                          <div class="form-group">
                          <label for="formrow-email-input">ID Contrat *</label>
                          <input
                            id="projectname"
                            v-model="contractForm.contractRef"
                            type="text"
                            class="form-control"
                            @blur="$v.contractForm.contractRef.$touch"
                            :class="{
                              'is-invalid':
                                submitted && $v.contractForm.contractRef.$error,
                            }"
                            placeholder=""
                          />
                          <div
                            v-if="
                              submitted && $v.contractForm.contractRef.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contractForm.contractRef.required"
                              >La référence du contrat est obligatoire.</span
                            >
                            <br />
                            <span v-if="!$v.contractForm.contractRef.maxLength"
                              >La taille minimum du champs est:
                              <b>{{ this.vrules.contractRef.maxLength }}</b>
                              Caractères.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="formrow-email-input">N° Contrat *</label>
                          <input
                            id="projectname"
                            v-model="contractForm.contractRef"
                            type="text"
                            class="form-control"
                            @blur="$v.contractForm.contractRef.$touch"
                            :class="{
                              'is-invalid':
                                submitted && $v.contractForm.contractRef.$error,
                            }"
                            placeholder=""
                          />
                          <div
                            v-if="
                              submitted && $v.contractForm.contractRef.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contractForm.contractRef.required"
                              >La référence du contrat est obligatoire.</span
                            >
                            <br />
                            <span v-if="!$v.contractForm.contractRef.maxLength"
                              >La taille minimum du champs est:
                              <b>{{ this.vrules.contractRef.maxLength }}</b>
                              Caractères.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="formrow-email-input">Désignation *</label>
                          <input
                            id="projectname"
                            v-model="contractForm.contractRef"
                            type="text"
                            class="form-control"
                            @blur="$v.contractForm.contractRef.$touch"
                            :class="{
                              'is-invalid':
                                submitted && $v.contractForm.contractRef.$error,
                            }"
                            placeholder=""
                          />
                          <div
                            v-if="
                              submitted && $v.contractForm.contractRef.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contractForm.contractRef.required"
                              >La référence du contrat est obligatoire.</span
                            >
                            <br />
                            <span v-if="!$v.contractForm.contractRef.maxLength"
                              >La taille minimum du champs est:
                              <b>{{ this.vrules.contractRef.maxLength }}</b>
                              Caractères.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="formrow-email-input">Objet *</label>
                          <input
                            id="projectname"
                            v-model="contractForm.contractRef"
                            type="text"
                            class="form-control"
                            @blur="$v.contractForm.contractRef.$touch"
                            :class="{
                              'is-invalid':
                                submitted && $v.contractForm.contractRef.$error,
                            }"
                            placeholder=""
                          />
                          <div
                            v-if="
                              submitted && $v.contractForm.contractRef.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contractForm.contractRef.required"
                              >La référence du contrat est obligatoire.</span
                            >
                            <br />
                            <span v-if="!$v.contractForm.contractRef.maxLength"
                              >La taille minimum du champs est:
                              <b>{{ this.vrules.contractRef.maxLength }}</b>
                              Caractères.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="formrow-password-input"
                            >Prestataire *</label
                          >
                          <select
                            id="projectname"
                            v-model="contractForm.contractSupplier"
                            type="text"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.contractForm.contractSupplier.$error,
                            }"
                            placeholder=""
                          >
                            <option value=""></option>
                            <option
                              v-for="supplier in suppliers"
                              v-bind:value="supplier.id"
                              v-bind:key="supplier.id"
                            >
                              {{ supplier.name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              submitted &&
                                $v.contractForm.contractSupplier.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="!$v.contractForm.contractSupplier.required"
                              >Le prestataire est obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="formrow-email-input">Attachement</label>
                          <input
                            id="projectname"
                            type="file"
                            class="form-control"
                            @blur="$v.contractForm.contractRef.$touch"
                            :class="{
                              'is-invalid':
                                submitted && $v.contractForm.contractRef.$error,
                            }"
                            placeholder=""
                          />
                          <div
                            v-if="
                              submitted && $v.contractForm.contractRef.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contractForm.contractRef.required"
                              >La référence du contrat est obligatoire.</span
                            >
                            <br />
                            <span v-if="!$v.contractForm.contractRef.maxLength"
                              >La taille minimum du champs est:
                              <b>{{ this.vrules.contractRef.maxLength }}</b>
                              Caractères.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label for="formrow-inputCity">Type *</label>
                          <select
                            class="form-control"
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.contractForm.contractType.$error,
                            }"
                            v-model="contractForm.contractType"
                          >
                            <option value=""></option>
                            <option
                              v-for="type in types"
                              v-bind:value="type.id"
                              v-bind:key="type.id"
                            >
                              {{ type.name }}
                            </option>
                          </select>
                          <div
                            v-if="
                              submitted && $v.contractForm.contractType.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contractForm.contractType.required"
                              >Le Type est obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="formrow-email-input">Domiciliation *</label>
                          <input
                            id="projectname"
                            v-model="contractForm.contractRef"
                            type="text"
                            class="form-control"
                            @blur="$v.contractForm.contractRef.$touch"
                            :class="{
                              'is-invalid':
                                submitted && $v.contractForm.contractRef.$error,
                            }"
                            placeholder=""
                          />
                          <div
                            v-if="
                              submitted && $v.contractForm.contractRef.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.contractForm.contractRef.required"
                              >La référence du contrat est obligatoire.</span
                            >
                            <br />
                            <span v-if="!$v.contractForm.contractRef.maxLength"
                              >La taille minimum du champs est:
                              <b>{{ this.vrules.contractRef.maxLength }}</b>
                              Caractères.</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>


                    

                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity"
                            >Date début de validité *</label
                          >
                          <input
                            id="projectname"
                            v-model="contractForm.contractPeriodFrom"
                            type="date"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.contractForm.contractPeriodFrom.$error,
                            }"
                            placeholder=""
                          />
                          <div
                            v-if="
                              submitted &&
                                $v.contractForm.contractPeriodFrom.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="
                                !$v.contractForm.contractPeriodFrom.required
                              "
                              >La date début du contrat est obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputState"
                            >Date d'expiration *</label
                          >
                          <input
                            id="projectname"
                            v-model="contractForm.contractPeriodTo"
                            type="date"
                            class="form-control"
                            placeholder=""
                            :class="{
                              'is-invalid':
                                submitted &&
                                $v.contractForm.contractPeriodTo.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitted &&
                                $v.contractForm.contractPeriodTo.$error
                            "
                            class="invalid-feedback"
                          >
                            <span
                              v-if="!$v.contractForm.contractPeriodTo.required"
                              >La date fin du contrat est obligatoire.</span
                            >
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputCity"
                            >Date de signature</label
                          >
                          <input
                            id="projectname"
                            v-model="contractForm.contractSignatureDate"
                            type="date"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="formrow-inputState"
                            >Date d’entrée en vigueur</label
                          >
                          <input
                            id="projectname"
                            v-model="contractForm.contractEffectiveDate"
                            type="date"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </tab-content>
              <!-- Périmètre Contractuel -->
              <tab-content icon="mdi mdi-face-profile" title="2- Périmètre Contractuel">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name"
                        >First name</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          value="Francis"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="surname"
                        >Last name</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          name="surname"
                          class="form-control"
                          value="Brinkman"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                        >Email</label
                      >
                      <div class="col-md-9">
                        <input
                          type="email"
                          name="email"
                          class="form-control"
                          value="cory1979@hotmail.com"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>

              <tab-content icon="mdi mdi-face-profile" title="3- Fournitures & Services">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name"
                        >First name</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          value="Francis"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="surname"
                        >Last name</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          name="surname"
                          class="form-control"
                          value="Brinkman"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                        >Email</label
                      >
                      <div class="col-md-9">
                        <input
                          type="email"
                          name="email"
                          class="form-control"
                          value="cory1979@hotmail.com"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>

              <tab-content icon="mdi mdi-face-profile" title="4- Aspects Financiers">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name"
                        >First name</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          value="Francis"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="surname"
                        >Last name</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          name="surname"
                          class="form-control"
                          value="Brinkman"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                        >Email</label
                      >
                      <div class="col-md-9">
                        <input
                          type="email"
                          name="email"
                          class="form-control"
                          value="cory1979@hotmail.com"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>

              <tab-content icon="mdi mdi-face-profile" title="5- Synoptique Contrat">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name"
                        >First name</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          name="name"
                          class="form-control"
                          value="Francis"
                        />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="surname"
                        >Last name</label
                      >
                      <div class="col-md-9">
                        <input
                          type="text"
                          name="surname"
                          class="form-control"
                          value="Brinkman"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="email"
                        >Email</label
                      >
                      <div class="col-md-9">
                        <input
                          type="email"
                          name="email"
                          class="form-control"
                          value="cory1979@hotmail.com"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>

              <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <h2 class="mt-0">
                        <i class="mdi mdi-check-all"></i>
                      </h2>
                      <h3 class="mt-0">Thank you !</h3>

                      <p class="w-75 mb-2 mx-auto">
                        Quisque nec turpis at urna dictum luctus. Suspendisse
                        convallis dignissim eros at volutpat. In egestas mattis
                        dui. Aliquam mattis dictum aliquet.
                      </p>

                      <div class="mb-3">
                        <div class="custom-control custom-checkbox">
                          <input
                            id="customCheck1"
                            type="checkbox"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="customCheck1"
                            >I agree with the Terms and Conditions</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
